<template>
	<!--用户信息 -->
    <div class="user-info">
		<!-- 自定义头部 -->
		<div class="main bg-fff">
			<div class="item flex">
				<div class="title">
					真实姓名
				</div>
				<div class="value flex">
					<span>{{userParadigm.realName}}</span>
            		<img src="@/assets/yq/mine-arrow.png" class="arrow" />
				</div>
			</div>
			<div class="item flex">
				<div class="title">
					出生年月
				</div>
				<div class="value flex">
					<span>{{userParadigm.birthday}}</span>
            		<img src="@/assets/yq/mine-arrow.png" class="arrow" />
				</div>
			</div>
			<div class="item flex">
				<div class="title">
					性别
				</div>
				<div class="value flex">
					<span>{{userParadigm.genderName}}</span>
            		<img src="@/assets/yq/mine-arrow.png" class="arrow" />
				</div>
			</div>
			<div class="item flex" @click="goModify">
				<div class="title">
					绑定手机
				</div>
				<div class="value flex">
					<span>{{userParadigm.phone}}</span>
            		<img src="@/assets/yq/mine-arrow.png" class="arrow" />
				</div>
			</div>
		</div>
		<div class="login-out bg-fff" 
			@click="loginOut"
		>
			退出登录
		</div>
	</div>
</template>

<script>
import { getUserInfo } from "@/api/user";
import { removeAllUserInfo,getUserinfo,setUserinfo } from "@/utils/auth";
import { Dialog } from 'vant';
export default {
	components: {
		
	},
	computed: {
		userParadigm() {
			if(this.userInfo.gender){
				this.userInfo.genderName = this.userInfo.gender==1? '男' : "女";
			}
			return this.userInfo;
		}
	},
	data() {
		return {
			title:"个人信息",
			backVisible:true,
			userInfo:{},
		}
	},	
	methods: {
		goModify(){
			this.$router.push({
				path:"/modifyPhone"
			})
		},
		loginOut(){
			Dialog.confirm({
				title: '提示',
				message: '确定退出吗~',
			})
			.then(() => {
				this.$store.dispatch('user/loginOut');
				this.$router.push({
					path:"/login"
				})
			})
			
		},
	},
	created(){
		//判断用户信息
		if (!getUserinfo()) {
            getUserInfo().then((res) => {
                let { status, statusCode } = res;
                if (status && statusCode === 0) {
                    this.userInfo = res.data;
                    setUserinfo(JSON.stringify(res.data));
                }
            });
        } else {
            this.userInfo = JSON.parse(getUserinfo());
        }
	}
};
</script>

<style scoped>
div{
	box-sizing: border-box;
}
.user-info{
	padding-top: 50px;
}
.user-info .main{
	padding: 5px 18px 0 18px;
}
.user-info .item{
	height: 59px;
	align-items: center;
	justify-content: space-between;
	border-bottom: 0.5px solid #EDF1F4;
}
.user-info .item .title{
	/* color: #222; */
	font-weight: 500;
}
.user-info .item .value{
	align-items: center;
}
.user-info .item .value span{
	font-size: 14px;
	color: #999;
	margin-right: 10px;
}
.user-info .item .arrow{
	margin-left: auto;
	height: 11px;
	width: 6px;
}
.user-info .login-out{
	height: 59px;
	line-height: 59px;
	text-align: center;
	font-weight: 400;
	margin-top: 10px;
}

</style>